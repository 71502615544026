import { useStore } from "../store/Store";
import ProfileFoto from "../components/ProfileFoto";
import ProgressBar from "../components/ProgressBar";
import { cintakyu } from "../utils/CintaKyu";
import { supabase } from "../supabase";

import { useWindowSize } from "react-use";
import Confetti from "react-confetti";

function Home({ alumno }) {
  const logout = useStore((state) => state.logout);
  const user = useStore((state) => state.user);
  const { width, height } = useWindowSize();

  const emptyStar =
    "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694401497/empty_start_ltbd9n.png";
  const halfStar =
    "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694401498/half_start_z4kyim.png";
  const fullStar =
    "https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694401497/full_start_vlkncq.png";

  function handleLogout() {
    logout(user);
  }

  async function handleInscripcion() {
    supabase
      .from("Alumnos")
      .update({ Examen: true })
      .eq("id", alumno.id)
      .then(({ data, error }) => {
        if (error) console.log(error);
        else console.log(data);
      });
    supabase
      .from("Koins")
      .insert({ alumno: alumno.id, koins: -360 })
      .then(({ data, error }) => {
        if (error) console.log(error);
        else window.location.reload();
      });

    const msg = `${alumno.Nickname} se inscribió al examen`;
    const url = `https://kumoappback.vercel.app/telegram_msg?msg=${msg}`;
    await fetch(url).then((res) => {
      console.log(res);
    });
  }



  return (
    <div className="bg-black w-full min-h-screen pb-20 flex flex-col items-center justify-center">
      {Object.keys(alumno).length === 0 ? (
        <div>Loading...</div>
      ) : (
        <>
          <ProfileFoto foto={alumno.Foto} />
          <div
            className="text-white font-semibold text-3xl font-supercell"
            onClick={handleLogout}
          >
            {alumno.Nickname}
          </div>
          <div className="text-white font-semibold text-xl font-supercell">
            {`Cinta ${cintakyu[alumno.Nivel]["cinta"]}`}
          </div>
          <div className="text-white font-semibold text-xl font-supercell">
            {`${cintakyu[alumno.Nivel]["kyu"]} kyu`}
          </div>
          {alumno.Koins < 360 && !alumno.Examen && (
            <div className="w-4/6 mt-10 flex flex-row justify-between items-center">
              <img
                src={
                  alumno.Koins >= 120
                    ? fullStar
                    : alumno.Koins >= 60
                      ? halfStar
                      : emptyStar
                }
                alt="empty star"
                className="w-14 h-14 -rotate-12"
              />
              <img
                src={
                  alumno.Koins >= 240
                    ? fullStar
                    : alumno.Koins >= 180
                      ? halfStar
                      : emptyStar
                }
                alt="empty star"
                className="w-14 h-14"
              />
              <img
                src={
                  alumno.Koins >= 360
                    ? fullStar
                    : alumno.Koins >= 300
                      ? halfStar
                      : emptyStar
                }
                alt="empty star"
                className="w-14 h-14 rotate-12"
              />
            </div>
          )}

          {alumno.Koins < 359 && !alumno.Examen ? (
            <ProgressBar percent={(alumno.Koins / 360) * 100} />
          ) : alumno.Examen ? (
            <div className="flex flex-col justify-center items-center">
              <Confetti width={width} height={height} />
              <p className="text-white p-5 text-center">
                ¡¡ 🎊 Ya estás inscrito al examen 🎉 !! <br />
                <br /> Nos vemos el 24 de Junio
              </p>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <p className="text-white p-5 text-center">
                ¡¡ 🎊 Felicidades 🎉 !! <br /> Ya tienes derecho a examen
              </p>
              <button
                onClick={handleInscripcion}
                className="text-white bg-red-500 px-5 py-2 rounded-full text-center "
              >
                Inscríbete al examen
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Home;
